import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Product from './pages/Product';
import '@fortawesome/fontawesome-svg-core/styles.css';
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndCondition";
import { ToastContainer } from "react-toastify";

function App() {
    return (
        <div className="min-h-screen overflow-y-scroll h-64 custom-scrollbar">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Router>
                {/* header here */}
                <main>
                    <Routes>
                        <Route path="/" element={<Home />}/>
                        <Route path="/product/*" element={<Product />}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
                        {/* <Route path="/route" element={<Element />}/> */}
                    </Routes>
                </main>
                {/* footer here */}
            </Router>
        </div>
    );
}

export default App;
